@use "./config/" as *;
@forward "./main-scss/";
@forward "./swiper/swiper-bundle";
.waf-auction {
    isolation: isolate;
    .overseas {
        &::after {
            aspect-ratio: 1 / 1;
            @include square(2rem);
            @include icon(overseas, 10);
            @extend %pure-white-900;
            @extend %flex-c-c;
            @extend %primary-400-bg;
            @extend %circle-radius;
        }
    }
    .status {
        display: inline-flex;
        align-items: center;
        border-radius: .2rem;
        @extend %py-1;
        @extend %px-2;
        @extend %accent-900-bg;
        &:before {
            content: '';
            @include background(null, "svg/live-blinker.svg", center / cover no-repeat);
            @include square(1.5rem);
            @extend %flex;
        }
        &-wrap {
            @extend %gap-1;
            @extend %capitalize;
            @extend %font-12-pb;
        }
    }
    .label {
        @extend %primary-50;
        @extend %font-12-pr;
    }
    .amount {
        @extend %pure-white-900;
    }
    .remain-amount {
        .amount {
            @extend %tertiary-600;
        }
    }
    .auction {
        &-banner-title {
            @extend %font-24-pb;
            @extend %text-center;
        }
        &-banner-wrapper {
            min-height: 25rem;
            border-radius: var(--full-radius) var(--full-radius) 0 0;
            @include background(clr(primary-900), "pattern/mobile/auction-soon-banner-bg.png", no-repeat bottom/cover);
            @extend %pure-white-900;
            @extend %gap-4;
            @extend %flex-column-c-c;
            @extend %px-4;
        }
        &-banner-meta {
            @extend %py-2;
            @extend %px-6;
            @extend %flex-column-n-c;
            @extend %primary-500-bg-5;
            @extend %half-radius;
            @extend %gap-4;
            .meta {
                max-width: fit-content;
                @extend %font-12-pb;
                @extend %d-block;
                @extend %w-100;
            }
        }
        &-masthead {
            min-height: 25rem;
            border-radius: var(--full-radius) var(--full-radius) 0 0;
            @include background(clr(primary-900), "pattern/mobile/auction-banner-bg.png", no-repeat center/cover);
            @extend %pure-white-900;
            @extend %p-4;
            @extend %relative;
            .player-meta::after {
                @extend %d-none;
            }
            .amount {
                @extend %font-18-pb;
            }
        }
        //DETAILS
        &-amount-details {
            border-radius: 0 0 var(--half-radius) var(--half-radius);
            flex-wrap: wrap;
            @extend %secondary-900-bg;
            @extend %p-2;
            @extend %flex;
            @extend %gap-2;
        }
        &-amount-item {
            flex: 1;
            min-width: calc((100% - var(--space-2)) / 2);
            @extend %pure-white-900-bg-1;
            @extend %half-radius;
            @extend %p-1;
            @extend %relative;
        }
        &-amount-wrap {
            @extend %relative;
            @extend %text-center;
            @extend %flex-column;
            @extend %gap-4;
            &::after {
                content: "";
                height: .01rem;
                top: 55%;
                @extend %w-100;
                @extend %absolute;
                @extend %primary-500-bg-6;
            }
            .label {
                @extend %flex-column;
            }
            .amount {
                @extend %font-18-pb;
            }
        }
    }
    .player {
        &-name {
            border-block: .1rem solid clr(primary-500, 6);
            @extend %font-16-pb;
            @extend %pure-white-900;
            @extend %py-1;
            @extend %flex-n-c;
            @extend %gap-2;
        }
        &-info {
            .text {
                @extend %font-12-pr;
                @extend %primary-50;
            }
        }
        &-image {
            aspect-ratio: 1 / 1;
            border: .2rem solid clr(tertiary-600);
            @include square(6rem);
            @extend %hidden;
            @extend %pure-white-900-bg;
            @extend %circle-radius;
        }
        &-details-card {
            @extend %gap-2;
            @extend %flex-n-c;
            @extend %py-4;
        }
        &-role {
            @extend %tertiary-600;
            @extend %font-12-pb;
            @extend %capitalize;
        }
        &-price-card {
            border: .1rem solid clr(primary-500, 6);
            @extend %primary-900-bg-6;
            @extend %half-radius;
            @extend %flex-n-c;
            @extend %p-4;
            @extend %relative;
            &:after {
                content: "";
                height: .01rem;
                @extend %w-50;
                @extend %primary-500-bg-6;
                @extend %pos-y-center
            }
        }
        &-status {
            @extend %text-center;
            @extend %ml-auto;
            @extend %flex-column-n-c;
            .image {
                height: 6rem;
            }
            .soldto-image {
                border: .2rem solid clr(tertiary-600);
                @include square(6rem);
                @extend %pure-white-900-bg;
                @extend %circle-radius;
                @extend %mb-2;
            }
            .text {
                @extend %font-12-pb;
                @extend %pure-white-900;
                @extend %mb-2;
            }
            .hammer-image {
                width: 7.5rem;
                height: 6rem;
            }
            .rtm-image, .unsold {
                width: 8rem;
                height: 6.5rem;
            }
        }
        &-meta {
            @include separator(70%, y);
            @extend %flex-column;
            @extend %gap-1;
        }
        &-price-wrapper {
            @extend %flex-column;
            @extend %gap-4;
        }
        //BOUGHT
        &-bought-table {
            @extend %my-6;
            .title {
                @extend %mb-6;
            }
            .amount {
                @extend %font-16-pb;
            }
            .player {
                &-details-card {
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0 var(--space-3) var(--space-6);
                }
                &-card-item {
                    @extend %relative;
                    &.overseas::after {
                        @include position(2rem, 2rem, null, null);
                    }
                }
                &-image {
                    @include square(9rem);
                    @extend %mt-9-neg;
                    @extend %pure-white-900-bg;
                }
                &-price-wrapper {
                    flex-direction: row;
                    border-radius: 0 0 var(--full-radius) var(--full-radius);
                    @extend %secondary-900-bg;
                    @extend %gap-0;
                }
                &-meta {
                    @extend %text-center;
                    @extend %p-3;
                }
                &-info {
                    @extend %pt-4;
                }
                &-name {
                    border-top: 0;
                    @extend %py-2;
                    @extend %mb-1;
                }
            }
        }
        &-card-list {
            @include card-count(1.1, var(--space-2));
            &.player-card-single {
                .player-card-item {
                    width: 100%;
                }
            }
        }
        &-card-item {
            border-radius: 0 0 var(--full-radius) var(--full-radius);
            @include background(clr(primary-900), "pattern/bought-player-bg.png", no-repeat center/cover);
            @extend %mt-9;
        }
        &-meta {
            flex: 1;
        }
        // RETAINED TABLE
        &-retained-table {
            // .filter-section {
            //     @extend %static;
            //     @extend %ml-auto;
            // }
            @extend %mt-6;
            > .head {
                @extend %mb-6;
                @extend %flex-n-c;
            }
            .player-name {
                border: 0;
                @extend %py-0;
                @extend %neutral-800;
            }
            .player-thumbnail {
                aspect-ratio: 1 / 1;
                border: .2rem solid clr(tertiary-600);
                object-fit: contain;
                padding: .2rem;
                @include square(4rem);
                @extend %circle-radius;
            }
            .player-role {
                @extend %font-12-pr;
                @extend %neutral-700;
            }
        }
    }
    .table {
        .text {
            @extend %capitalize;
        }
        &-data {
            @extend %py-1;
            @extend %px-2;
            &.players {
                justify-content: flex-start;
                @extend %text-left;
            }
            &.salary {
                justify-content: flex-end;
                width: 15rem;
                @extend %tertiary-600;
            }
        }
        &-head {
            .table-data {
                background: linear-gradient(91.83deg, var(--secondary-800) 0%, var(--primary-700) 100%);
                background-attachment: fixed;
                height: 4rem;
                &:first-child {
                    border-top-left-radius: var(--half-radius);
                }
                &:last-child {
                    border-top-right-radius: var(--half-radius);
                }
                &.players,
                &.salary {
                    @extend %font-14-pb;
                }
            }
        }
        &-body {
            @extend %pure-white-900-bg;
            .table-data {
                min-height: 7rem;
                @extend %relative;
                &::before {
                    content: '';
                    height: .1rem;
                    @extend %pos-bl;
                    @extend %w-100;
                    @extend %neutral-200-bg;
                }
                &.salary {
                    @extend %font-16-pb;
                }
            }
            .table-data-wrap {
                @extend %flex-n-c;
                @extend %gap-2;
            }
        }
    }
    .tabs {
        @include card-count(5.8, var(--space-2));
        @extend %py-4;
        @extend %px-2;
        @extend %pure-white-900-bg;
    }
    .tab {
        &-name {
            aspect-ratio: 1 / 1;
            border: .1rem solid clr(neutral-300);
            padding: .3rem;
            opacity: .7;
            cursor: pointer;
            @include square(5rem);
            @extend %pure-white-900-bg;
            @extend %circle-radius;
            &.active {
                opacity: 1;
                border: .2rem solid clr(tertiary-600);
                box-shadow: 0rem 0.13rem 0.14rem 0rem #00000029;
            }
        }
    }
    .swiper {
        &-button-prev,
        &-button-next {
            @extend %d-none;
        }
    }
    .no-data {
        border: .1rem solid var(--secondary-800);
        @extend %my-2;
        @extend %py-3;
        @extend %secondary-800;
        @extend %uppercase;
        @extend %font-14-pb;
        @extend %half-radius;
        @extend %flex-c-c;
    }
    .tooltip {
        @include position(0, null, null, 1rem);
        .icon {
            cursor: pointer;
            font-size: 0;
            &::after {
                @include icon(info, 10);
                @extend %pure-white-900;
            }
            &:hover + .tooltip-data {
                opacity: 1;
            }
        }
        &-data {
            width: 14rem;
            border: .1rem solid clr(neutral-300);
            opacity: 0;
            @include position(null, -12rem, 100%, null);
            @extend %p-1;
            @extend %full-radius;
            @extend %text-center;
            @extend %pure-white-900-bg;
            &:after {
                content: "";
                transform: translateX(-50%);
                border: .5rem solid;
                border-color: clr(pure-white-900) transparent transparent transparent;
                @include position(100%, null, null, 1.5rem);
            }
        }
        .description {
            @extend %font-11-pr;
        }
    }
}
//COMMENTING THIS UNTIL NEW DESIGNS ARRIVE
// .webview {
//     .waf-auction {
//         @extend %p-0;
//         .waf-head {
//             @extend %d-none;
//         }
//         .label {
//             font-size: 1rem;
//         }
//         .player-bought-table > .title,
//         .player-retained-table > .head > .title {
//             font-style: italic;
//             font-family: $font-secondary;
//             @extend %text-center;
//             @extend %relative;
//             &:after {
//                 content: "";
//                 width: 8rem;
//                 height: 1rem;
//                 bottom: -1.5rem;
//                 @include background(null, "pattern/line-with-dots-2.png", no-repeat bottom / contain);
//                 @extend %pos-x-center;
//             }
//         }
//         .auction {
//             &-banner {
//                 border-radius: 0;
//                 @include background(clr(primary-900), "pattern/auction-webview-banner-bg.png", no-repeat bottom/cover);
//                 @extend %py-6;
//             }
//             &-banner-wrapper {
//                 min-height: 22rem;
//                 @include background(null, "pattern/auction-banner-text-bg.png", no-repeat bottom/contain);
//                 @extend %py-8;
//                 @extend %flex-column-c-c;
//                 @extend %px-2;
//                 @extend %gap-6;
//             }
//             &-banner-title {
//                 font-family: $font-secondary;
//                 font-style: italic;
//                 @extend %relative;
//                 &:after {
//                     content: "";
//                     width: 13rem;
//                     height: 1rem;
//                     bottom: -1.5rem;
//                     @include background(null, "pattern/line-with-dots-1.png", no-repeat bottom / contain);
//                     @extend %pos-x-center;
//                 }
//             }
//             &-amount-item {
//                 background: linear-gradient(1.19deg, clr(secondary-800) 0.6%, clr(primary-700) 99.11%);
//             }
//             &-banner-meta {
//                 flex-direction: row;
//                 justify-content: center;
//                 @extend %p-0;
//                 @extend %transparent-bg;
//                 @extend %relative;
//                 @extend %gap-0;
//                 .date::after {
//                     content: "|";
//                     @extend %px-1;
//                 }
//                 .meta {
//                     @extend %tertiary-600;
//                 }
//             }
//             &-masthead {
//                 border-radius: 0;
//                 @include background(clr(primary-900), "pattern/auction-webview-banner-bg.png", no-repeat bottom/cover);
//                 @extend %py-6;
//                 @extend %relative;
//                 .player-info {
//                     @extend %gap-2;
//                     @extend %flex-column;
//                 }
//             }
//             //DETAILS
//             &-amount-details {
//                 border-radius: 0;
//                 @extend %py-4;
//             }
//             &-amount-item {
//                 @extend %full-radius;
//             }
//         }
//         .player {
//             &-image {
//                 @include square(8rem);
//             }
//             &-details-card {
//                 flex-direction: column;
//                 @extend %pt-0;
//                 @extend %text-center;
//             }
//             &-name {
//                 @extend %font-18-pb;
//                 .first-name {
//                     font-weight: 400;
//                 }
//             }
//             &-price-card {
//                 border: 0;
//                 min-height: 18rem;
//                 @include background(null, "pattern/auction-banner-text-bg.png", no-repeat bottom/100% 100%);
//                 @extend %py-8;
//             }
//             &-card-item {
//                 border-radius: 2rem;
//                 @include background(null, "pattern/player-bought-card-bg.png", top/contain);
//             }
//             &-card-swiper {
//                 @extend %px-3;
//             }
//             &-card-list {
//                 @extend %px-3;
//                 @extend %mx-3-neg;
//             }
//             &-bought-table {
//                 .player {
//                     &-name {
//                         flex-direction: column;
//                         align-items: flex-start;
//                     }
//                     &-info {
//                         @extend %pt-0;
//                     }
//                     &-card-item {
//                         @extend %mt-0;
//                     }
//                     &-image {
//                         margin-top: inherit;
//                         @include square(7rem);
//                     }
//                     &-price-wrapper {
//                         border-radius: 0 0 2rem 2rem;
//                     }
//                     &-details-card {
//                         flex-direction: row;
//                         align-items: center;
//                         @extend %text-left;
//                         @extend %p-3;
//                     }
//                     &-meta {
//                         padding: var(--space-2) var(--space-3);
//                         @extend %py-2;
//                         @extend %px-3;
//                     }
//                 }
//             }
//             &-retained-table {
//                 > .head {
//                     flex-direction: column;
//                     @extend %px-3;
//                     @extend %gap-8;
//                 }
//                 .player-name {
//                     font-size: 1.6rem;
//                     .first-name {
//                         font-weight: 700;
//                     }
//                 }
//             }
//         }
//         .status {
//             @extend %success-900-bg;
//             @extend %rounded-radius;
//             @extend %gap-1;
//             &-wrap {
//                 right: 2rem;
//                 @extend %absolute;
//             }
//         }
//         .table {
//             &-head {
//                 .table-data {
//                     &:first-child,
//                     &:last-child {
//                         border-radius: 0;
//                     }
//                 }
//             }
//         }
//         .filter-section {
//             @extend %px-2;
//             .btn-more {
//                 @extend %rounded-radius;
//                 @extend %neutral-200-bg;
//                 .btn-text {
//                     @extend %primary-900;
//                 }
//                 &:before {
//                     @include icon(filter, 14);
//                     @extend %primary-900;
//                 }
//             }
//             .more-filters {
//                 top: 0;
//                 height: var(--window-inner-height);
//             }
//             .selected-title {
//                 .title {
//                     width: fit-content;
//                     padding: var(--space-1) var(--space-2);
//                     @extend %tertiary-200-bg;
//                     @extend %rounded-radius;
//                 }
//             }
//             .footer {
//                 .btn {
//                     @extend %rounded-radius;
//                 }
//             }
//         }
//     }
// }
@include mq(col-md) {
    .waf-auction {
        .status-wrap {
            flex-basis: 100%;
            @include position(2rem, null, null, 4rem);
        }
        .auction {
            &-banner-wrapper {
                @include bgImg("pattern/auction-soon-banner-bg.png");
            }
            &-banner-title {
                font-size: 3.2rem;
            }
            &-banner-meta {
                flex-direction: row;
                .meta {
                    font-size: 1.6rem;
                }
            }
            &-masthead {
                min-height: 30rem;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                background-position: bottom;
                padding-inline: var(--space-8);
                @include bgImg("pattern/auction-banner-bg.png");
                .player-info {
                    gap: var(--space-2);
                    @include flex-config(flex, column);
                }
                .player-meta {
                    &::after {
                        display: none;
                    }
                }
                &-rtm,
                &-unsold {
                    .player-status .image {
                        height: 9rem;
                    }
                }
            }
            &-amount-wrap {
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                &::after {
                    width: .1rem;
                    height: 100%;
                    @include position-combo(y-center);
                }
                .amount {
                    font-size: 2.4rem;
                }
            }
            &-amount-item {
                padding: var(--space-2);
            }
        }
        .player {
            &-details-card {
                flex: 2;
            }
            &-price-card {
                flex-basis: 55rem;
            }
            &-image {
                @include square(10rem)
            }
            &-name {
                font-size: 2.4rem;
            }
            &-role {
                font-size: 1.4rem;
            }
            &-card-list {
                overflow-x: unset;
                gap: 0;
            }
            &-card-item {
                width: auto;
                border-radius: var(--full-radius);
            }
            &-bought-table {
                position: relative;
                .player {
                    &-name {
                        font-size: 2rem;
                    }
                    &-info {
                        padding-top: 0;
                    }
                    &-details-card {
                        padding: 0 var(--space-6) var(--space-4);
                    }
                }
            }
            &-retained-table {
                .player-thumbnail {
                    @include square(5rem)
                }
                .player-name {
                    font-size: 1.6rem;
                }
            }
        }
        .table {
            &-data {
                padding: var(--space-1) var(--space-4);
            }
            &-head {
                .table-data {
                    height: 6rem;
                    &.players,
                    &.salary {
                        font-size: 1.4rem;
                    }
                }
            }
            &-body {
                .table-data {
                    min-height: 8rem;
                    &.salary {
                        font-size: 1.8rem;
                    }
                }
            }
        }
        .tabs {
            justify-content: center;
            gap: var(--space-4);
        }
        .tab {
            &-name {
                @include square(6rem)
            }
        }
        .swiper {
            position: static;
            &-button-prev,
            &-button-next {
                display: flex;
            }
            &-button-next,
            &-button-prev {
                top: 60%;
                z-index: var(--z-swiper-button);
                @include square(2.4rem);
                &:after {
                    font-size: 1rem;
                }
            }
            &-button-prev {
                left: -1.2rem;
            }
            &-button-next {
                right: -1.2rem;
            }
        }
    }
}
@include mq(col-lg) {
    .waf-auction {
        .tooltip {
            top: 50%;
            right: 1rem;
            left: unset;
            transform: translateY(-50%);
            &-data {
                width: 20rem;
                padding: var(--space-2);
                right: 0;
                &::after {
                    right: 0;
                    left: unset;
                }
            }
            .description {
                font-size: 1.2rem;
            }
            .icon::after {
                font-size: 1.5rem;
            }
        }
        .player-status {
            .hammer-image {
                width: 9rem;
                height: 7.5rem;
            }
            .rtm-image, .unsold {
                width: 10.5rem;
                height: 8.5rem;
            }
        }
        .auction-amount-item {
            min-width: calc((100% - var(--space-2) * 3) / 4);
        }
    }
}